<template>
    <div class="home">
        <img alt="" src="../assets/bgImg.png" />
        <div class="num" @click="handleClick">苏ICP备19074666号-1</div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    methods: {
        handleClick() {
            window.open('https://beian.miit.gov.cn/#/Integrated/index');
        },
    },
};
</script>
<style scoped>
.home {
    position: relative;
}
.home img {
    display: block;
    width: 100vw;
    height: 100vh;
}
.home .num {
    position: absolute;
    bottom: 1.5vh;
    right: 30vw;
    color: #fff;
    font-size: 0.7vw;
    cursor: pointer;
}
</style>
